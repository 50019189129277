import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-intl'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
const NotFoundPage = ({ intl }) => {
  return (
    <Layout>
      <SEO title="error" />
      <section className="fullscreen">
        <div className="container v-align-transform">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="text-center">
                <i className="ti-receipt icon icon-lg mb24 mb-xs-0" />
                <h1 className="large">
                  {intl.formatMessage({ id: 'notfound.header' })}
                </h1>
                <p>{intl.formatMessage({ id: 'notfound.description' })}</p>
                <Link className="btn" to="/">
                  {intl.formatMessage({ id: 'notfound.back_btn' })}
                </Link>
                <Link className="btn" to="/contact">
                  {intl.formatMessage({ id: 'notfound.contact_btn' })}
                </Link>
              </div>
            </div>
          </div>
          <div className="embelish-icons">
            <i className="ti-help-alt" />
            <i className="ti-cross" />
            <i className="ti-support" />
            <i className="ti-announcement" />
            <i className="ti-signal" />
            <i className="ti-pulse" />
            <i className="ti-marker" />
            <i className="ti-pulse" />
            <i className="ti-alert" />
            <i className="ti-help-alt" />
            <i className="ti-alert" />
            <i className="ti-pulse" />
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default injectIntl(NotFoundPage)
